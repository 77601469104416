<template>
  <div>
    <div class="border border-solid border-blue-200 pt-4">
      <datatable
        :ajax="true"
        :ajax-pagination="true"
        :url="users.url"
        :columns="users.columns"
        :query="users.query"
        :on-click="click"
        sort="created_at"
        order="asc"
        ref="table"
      >
        <template #header>
          <search-form
            v-model="users.query"
            class="mb-5"
            placeholder="Search for user's name, status, etc."
            @submit="reloadTable"
          />
        </template>
      </datatable>
    </div>

    <modals-user
      :selected-user="users.selected"
      ref="userModal"
      @delete="userDeleted"
      @close="userModalClosed"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentFilter: null,
      users: this.$options.resource([], {
        url: `${this.$baseurl}/admin/users/credit-limits/reviewed`,
        query: '',
        selected: null,
        columns: [
          {
            name: 'user_id',
            th: 'User ID',
          },
          {
            name: 'name',
            th: 'Name',
            render: (user) => `${user.name} ${user.last_name}`,
          },
          {
            name: 'email',
            th: 'Email Address',
          },
          {
            name: 'status',
            th: 'Status',
          },
          {
            name: 'desired_limit',
            th: 'Requested Limit',
            render: (request) =>
              this.$options.filters.currency(request.desired_limit),
          },

          {
            name: 'reviewed_at',
            th: 'Reviewed At',
            render: ({ reviewed_at }) =>
              this.$moment(reviewed_at).format('MMM Do YYYY'),
            sortable: false,
          },
        ],
      }),
    };
  },
  methods: {
    click(user) {
      this.users.selected = user;
      // this.$refs.userModal.open();
    },
    reloadTable() {
      this.$refs.table.loadAjaxData();
    },
    userDeleted() {
      this.reloadTable();
    },
    userModalClosed() {
      this.users.selected = null;
    },
  },
};
</script>
